$(window).on("load",function(){
	//common
	var screen_width = $(window).width();
	var timer = false;

	//windowサイズ取得で実行関数判定
	//769px以上
	if(screen_width > 768) {
		Responsive_higher769();
	//768px以下
	} else if(screen_width <= 768) {
        Responsive_lower768();
    }
	
	$(window).resize(function() {
		var resize_screen_width = $(window).width();
		if (timer !== false) {
			clearTimeout(timer);
		}
		timer = setTimeout(function() {
			if(resize_screen_width <= 768 && screen_width != resize_screen_width) {
				location.reload();
				screen_width = resize_screen_width;
			}
		}, 200);
    });
	
	//769px以上の関数
	function Responsive_higher769() {
		//pc banner slides
		$('#banner_box01 .sp_banner_slides').slick({
			// dots: true,
			infinite: true,
			speed: 300,
			slidesToShow: 1,
			centerMode: true,
			variableWidth: true,
			slidesToScroll: 1,
			arrows: false,
			autoplay: true,
			autoplaySpeed: 3000,
		});
    }
	//768px以下の関数
    function Responsive_lower768() {
		//sp banner slides
		$('.sp_banner_slides').slick({
			centerMode: true,
			centerPadding: '80px',
			slidesToShow: 1,
			infinite: true,
			dots: true,
			arrows: false,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 5000,
		});
		//sp slides
		$('.sp_slides').slick({
			centerMode: true,
			centerPadding: '55px',
			slidesToShow: 1,
			infinite: true,
			dots: true,
			arrows: false,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 5000,
		});
    }
	
	
	//main visual
	$('#main_visual .slides').slick({
		autoplay: true,
		autoplaySpeed: 6000,
		dots: true,
		infinite: true,
		speed: 1000,
		fade: true,
		cssEase: 'linear',
		pauseOnFocus: false,
		pauseOnHover: false,
	});
	setTimeout(function(){
		$('body#index #veil').fadeOut();
	}, 1000);
	/*
	setTimeout(function(){
		$('#main_visual .slides').slick('play');
	}, 2000);
	$('#main_visual .slides').on('beforeChange',function(event, slick, currentSlide, nextSlide){
		if(nextSlide > 0 ) {
			$('#main_visual .slides').slick('slickSetOption', 'autoplaySpeed', 10000, true)
		}
	});
	*/
	
	//fadein action
	var fade_target = '';
	$('#contents .slides, .sp_banner_slides, #pick_up ul, .section_flex, .works_select, .works_select ul, .fade_item, .concept_contents, .concept_lead, #sbi_images').on('inview', function(event, isInView) {
		if($(this).hasClass("sp_banner_slides")) {
			fade_target = ".banner_img";
		} else if($(this).hasClass("slides")) {
			fade_target = ".slide";
		} else if($(this).hasClass("section_flex")) {
			fade_target = ".fade_content";
		} else if($(this).hasClass("concept_contents")) {
			fade_target = ".fade_item";
		} else if($(this).hasClass("concept_lead")) {
			fade_target = "p";
		} else if($(this).attr("id") == "sbi_images") {
			fade_target = ".sbi_item";
		} else {
			fade_target = "li";
		}
		$(fade_target,this).css({opacity: 0}).each(function(i){
			$(this).delay(200 * i).animate({opacity:1}, 250);
		});
		$(this).off('inview');
	});
	// $('#covid19').on('inview', function(event, isInView) {
	// 	$(this).css({opacity: 0}).each(function(i){
	// 		$(this).delay(200 * i).animate({opacity:1}, 250);
	// 	});
	// 	$(this).off('inview');
	// });
	$('.fade_button').on('inview', function(event, isInView) {
		$(this).css({opacity: 0}).each(function(i){
			$(this).delay(200 * i).animate({opacity:1}, 500);
		});
		$(this).off('inview');
	});
	/*
	$('.fade_item').on('inview', function(event, isInView) {
		$(this).css({opacity: 0}).each(function(i){
			$(this).delay(200 * i).animate({opacity:1}, 300);
		});
		$(this).off('inview');
	});
	*/
	$('#concept').on('inview', function(event, isInView) {
		$(this).addClass('fadeout');
		$(this).off('inview');
	});
	
	
    
	
	//sp slides
	/*
	$('.sp_slides').slick({
		responsive: [
			{
				breakpoint: 9999,
				settings: "unslick"
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					dots: true
				}
			}
		]
	});
	*/
});



